import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"
//import ReactToPdf from "react-to-pdf";
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'

import './app.css';
import { statusName,ediFlg,toxicSubstanceName } from '../../constants'
import SpaceRow from '../commons/SpaceRow';

import GarbageCsvListTableForm from "./GarbageCsvListTableForm"
import { getGarbageDetailCsvOperation  } from '../../lib/api/garbage_detail_csv';
import { getGarbageCancelCsvCreate  } from '../../lib/api/garbage_cancel_csv';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_GarbageCsv',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function GarbageCsvListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser, handleCancel } = props;

  const [dataList2, setDataList2] = useState({});
  const [dataList3, setDataList3] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [curData, setCurData] = useState();

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    manifestNo: '',
//    wasteKindName: '',
    wasteName: '',
    statusName: '',
    exhaustName: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.exhaustName+"_"+filter.wasteName+"_"+filter.sect1TransportName;
    console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getGarbageDetailCsvOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  useEffect(() => {
    // console.log("dataList3",dataList3);
    }, []);

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2.id",dataList2.id);
//    handleGetPhotos(dataList2.id);
  };

  const handleExportCancelCSV = async () => {
    if (window.confirm(' CSVファイル（取消分・UTF-8形式）を作成しダウンロードします。よろしいですか？')) {
      try {
        const response = await getGarbageCancelCsvCreate(currentUser.traderCd);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const today = dayjs().format('YYYYMMDD');
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SDGs12_CancelData_'+today+'.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        window.alert(' CSVファイル 「'+ a.download + '」をダウンロードしました。')
      } catch (error) {
        console.error('CSVエクスポートエラー:', error);
      }
    }
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);


  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // 行の取消（論理削除）
  const cancelRows = () => {
    // console.log('cancelRows',dataList2)
    handleCancel(dataList2);
  }

  const qrCodeUrl = "https://www.sdgs12.com/waste/" + dataList2.garbageTag;
  // console.log('qrCodeUrl', qrCodeUrl);

  const cols = [
    {
      field: 'manifestNo',
      headerName: 'マニフェスト番号',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '最新状態',
      width: 110
    },
    {
      field: 'arrangementNo',
      headerName: '整理番号',
      width: 110
    },
    {
      field: 'denshi',
      headerName: '電子/紙',
      width: 110
    },
    {
      field: 'garbageTag',
      headerName: 'ごみタグ番号',
      width: 200
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出業者名称',
      width: 300
    },
    {
      field: 'exhaustZip',
      headerName: '排出事業者郵便番号',
      width: 150
    },
    {
      field: 'exhaustAddress',
      headerName: '排出業者住所',
      width: 150
    },
    {
      field: 'exhaustPhone',
      headerName: '排出事業者電話番号',
      width: 150
    },
    {
      field: 'exhaustFax',
      headerName: '排出事業者FAX番号',
      width: 150
    },
    {
      field: 'exhaustChargeName',
      headerName: '排出担当者名',
      width: 150
    },
    {
      field: 'wasteKindName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物名称',
      width: 240
    },
    {
      field: 'toxicName01',
      headerName: '有害物質',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'amount',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'packingAmount',
      headerName: '荷姿の数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'amount1',
      headerName: '明細数量1',
      width: 100
    },
    {
      field: 'memo1',
      headerName: 'メモ1',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '明細荷姿数量1',
      width: 100
    },
    {
      field: 'amount2',
      headerName: '明細数量2',
      width: 100
    },
    {
      field: 'memo2',
      headerName: 'メモ2',
      width: 100
    },
    {
      field: 'packingAmount2',
      headerName: '明細荷姿数量2',
      width: 100
    },
    {
      field: 'amount3',
      headerName: '明細数量3',
      width: 100
    },
    {
      field: 'unitName3',
      headerName: '明細単位3',
      width: 100
    },
    {
      field: 'memo3',
      headerName: 'メモ3',
      width: 100
    },
    {
      field: 'packingName3',
      headerName: '明細荷姿3',
      width: 100
    },
    {
      field: 'packingAmount3',
      headerName: '明細荷姿数量3',
      width: 100
    },
    {
      field: 'amount4',
      headerName: '明細数量4',
      width: 100
    },
    {
      field: 'unitName4',
      headerName: '明細単位4',
      width: 100
    },
    {
      field: 'memo4',
      headerName: 'メモ4',
      width: 100
    },
    {
      field: 'packingName4',
      headerName: '明細荷姿4',
      width: 100
    },
    {
      field: 'packingAmount4',
      headerName: '明細荷姿数量4',
      width: 100
    },
    {
      field: 'amount5',
      headerName: '明細数量5',
      width: 100
    },
    {
      field: 'unitName5',
      headerName: '明細単位5',
      width: 100
    },
    {
      field: 'memo5',
      headerName: 'メモ5',
      width: 100
    },
    {
      field: 'packingName5',
      headerName: '明細荷姿5',
      width: 100
    },
    {
      field: 'packingAmount5',
      headerName: '明細荷姿数量5',
      width: 100
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 100
    },
    {
      field: 'sect1TransportZip',
      headerName: '区間１運搬事業者郵便番号',
      width: 100
    },
    {
      field: 'sect1TransportAddress',
      headerName: '区間１運搬事業者住所',
      width: 100
    },
    {
      field: 'sect1TransportPhone',
      headerName: '区間１運搬事業者電話番号',
      width: 100
    },
    {
      field: 'sect1TransportFax',
      headerName: '区間１運搬事業者FAX番号',
      width: 100
    },
    {
      field: 'sect1TransportPermitNo',
      headerName: '区間１運搬事業者許可番号',
      width: 100
    },
    {
      field: 'sect1TransportMethodName',
      headerName: '区間１運搬　方法',
      width: 100
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '区間１運搬担当者',
      width: 100
    },
    {
      field: 'sect1CarNo',
      headerName: '区間１車両番号',
      width: 100
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業場名称',
      width: 100
    },
    {
      field: 'destination1Zip',
      headerName: '区間１運搬先事業場郵便番号',
      width: 100
    },
    {
      field: 'destination1Address',
      headerName: '区間１運搬先事業場住所',
      width: 100
    },
    {
      field: 'destination1Phone',
      headerName: '区間１運搬先事業場電話番号',
      width: 100
    },
    {
      field: 'destination1Fax',
      headerName: '区間１運搬先事業場FAX番号',
      width: 100
    },
    {
      field: 'destination1ChargeName',
      headerName: '区間１運搬先事業場担当者',
      width: 100
    },
    {
      field: 'sect1At',
      headerName: '区間１積込日',
      width: 100
    },
    {
      field: 'destination1At',
      headerName: '区間１荷降日',
      width: 100
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      width: 100
    },
    {
      field: 'sect2TransportZip',
      headerName: '区間２運搬事業者郵便番号',
      width: 100
    },
    {
      field: 'sect2TransportAddress',
      headerName: '区間２運搬事業者住所',
      width: 100
    },
    {
      field: 'sect2TransportPhone',
      headerName: '区間２運搬事業者電話番号',
      width: 100
    },
    {
      field: 'sect2TransportFax',
      headerName: '区間２運搬事業者FAX番号',
      width: 100
    },
    {
      field: 'sect2TransportPermitNo',
      headerName: '区間２運搬事業者許可番号',
      width: 100
    },
    {
      field: 'sect2TransportMethodName',
      headerName: '区間２運搬　方法',
      width: 100
    },
    {
      field: 'sect2TransportChargeName',
      headerName: '区間２運搬担当者',
      width: 100
    },
    {
      field: 'sect2CarNo',
      headerName: '区間２車両番号',
      width: 100
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業場名称',
      width: 100
    },
    {
      field: 'destination2Zip',
      headerName: '区間２運搬先事業場郵便番号',
      width: 100
    },
    {
      field: 'destination2Address',
      headerName: '区間２運搬先事業場住所',
      width: 100
    },
    {
      field: 'destination2Phone',
      headerName: '区間２運搬先事業場電話番号',
      width: 100
    },
    {
      field: 'destination2Fax',
      headerName: '区間２運搬先事業場FAX番号',
      width: 100
    },
    {
      field: 'destination2ChargeName',
      headerName: '区間２運搬先事業場担当者',
      width: 100
    },
    {
      field: 'sect2At',
      headerName: '区間２積込日',
      width: 100
    },
    {
      field: 'destination2At',
      headerName: '区間２荷降日',
      width: 100
    },
    {
      field: 'sect3TransportName',
      headerName: '区間３運搬事業者',
      width: 100
    },
    {
      field: 'sect3TransportZip',
      headerName: '区間３運搬事業者郵便番号',
      width: 100
    },
    {
      field: 'sect3TransportAddress',
      headerName: '区間３運搬事業者住所',
      width: 100
    },
    {
      field: 'sect3TransportPhone',
      headerName: '区間３運搬事業者電話番号',
      width: 100
    },
    {
      field: 'sect3TransportFax',
      headerName: '区間３運搬事業者FAX番号',
      width: 100
    },
    {
      field: 'sect3TransportPermitNo',
      headerName: '区間３運搬事業者許可番号',
      width: 100
    },
    {
      field: 'sect3TransportMethodName',
      headerName: '区間３運搬　方法',
      width: 100
    },
    {
      field: 'sect3TransportChargeName',
      headerName: '区間３運搬担当者',
      width: 100
    },
    {
      field: 'sect3CarNo',
      headerName: '区間３車両番号',
      width: 100
    },
    {
      field: 'destination3Name',
      headerName: '区間３運搬先事業場名称',
      width: 100
    },
    {
      field: 'destination3Zip',
      headerName: '区間３運搬先事業場郵便番号',
      width: 100
    },
    {
      field: 'destination3Address',
      headerName: '区間３運搬先事業場住所',
      width: 100
    },
    {
      field: 'destination3Phone',
      headerName: '区間３運搬先事業場電話番号',
      width: 100
    },
    {
      field: 'destination3Fax',
      headerName: '区間３運搬先事業場FAX番号',
      width: 100
    },
    {
      field: 'destination3ChargeName',
      headerName: '区間３運搬先事業場担当者',
      width: 100
    },
    {
      field: 'sect3At',
      headerName: '区間３積込日',
      width: 100
    },
    {
      field: 'destination3At',
      headerName: '区間３荷降日',
      width: 100
    },
    {
      field: 'sect4TransportName',
      headerName: '区間４運搬事業者',
      width: 100
    },
    {
      field: 'sect4TransportZip',
      headerName: '区間４運搬事業者郵便番号',
      width: 100
    },
    {
      field: 'sect4TransportAddress',
      headerName: '区間４運搬事業者住所',
      width: 100
    },
    {
      field: 'sect4TransportPhone',
      headerName: '区間４運搬事業者電話番号',
      width: 100
    },
    {
      field: 'sect4TransportFax',
      headerName: '区間４運搬事業者FAX番号',
      width: 100
    },
    {
      field: 'sect4TransportPermitNo',
      headerName: '区間４運搬事業者許可番号',
      width: 100
    },
    {
      field: 'sect4TransportMethodName',
      headerName: '区間４運搬　方法',
      width: 100
    },
    {
      field: 'sect4TransportChargeName',
      headerName: '区間４運搬担当者',
      width: 100
    },
    {
      field: 'sect4CarNo',
      headerName: '区間４車両番号',
      width: 100
    },
    {
      field: 'destination4Name',
      headerName: '区間４運搬先事業場名称',
      width: 100
    },
    {
      field: 'destination4Zip',
      headerName: '区間４運搬先事業場郵便番号',
      width: 100
    },
    {
      field: 'destination4Address',
      headerName: '区間４運搬先事業場住所',
      width: 100
    },
    {
      field: 'destination4Phone',
      headerName: '区間４運搬先事業場電話番号',
      width: 100
    },
    {
      field: 'destination4Fax',
      headerName: '区間４運搬先事業場FAX番号',
      width: 100
    },
    {
      field: 'destination4ChargeName',
      headerName: '区間４運搬先事業場担当者',
      width: 100
    },
    {
      field: 'sect4At',
      headerName: '区間４積込日',
      width: 100
    },
    {
      field: 'destination4At',
      headerName: '区間４荷降日',
      width: 100
    },
    {
      field: 'sect5TransportName',
      headerName: '区間５運搬事業者',
      width: 100
    },
    {
      field: 'sect5TransportZip',
      headerName: '区間５運搬事業者郵便番号',
      width: 100
    },
    {
      field: 'sect5TransportAddress',
      headerName: '区間５運搬事業者住所',
      width: 100
    },
    {
      field: 'sect5TransportPhone',
      headerName: '区間５運搬事業者電話番号',
      width: 100
    },
    {
      field: 'sect5TransportFax',
      headerName: '区間５運搬事業者FAX番号',
      width: 100
    },
    {
      field: 'sect5TransportPermitNo',
      headerName: '区間５運搬事業者許可番号',
      width: 100
    },
    {
      field: 'sect5TransportMethodName',
      headerName: '区間５運搬　方法',
      width: 100
    },
    {
      field: 'sect5TransportChargeName',
      headerName: '区間５運搬担当者',
      width: 100
    },
    {
      field: 'sect5CarNo',
      headerName: '区間５車両番号',
      width: 100
    },
    {
      field: 'destination5Name',
      headerName: '区間５運搬先事業場名称',
      width: 100
    },
    {
      field: 'destination5Zip',
      headerName: '区間５運搬先事業場郵便番号',
      width: 100
    },
    {
      field: 'destination5Address',
      headerName: '区間５運搬先事業場住所',
      width: 100
    },
    {
      field: 'destination5Phone',
      headerName: '区間５運搬先事業場電話番号',
      width: 100
    },
    {
      field: 'destination5Fax',
      headerName: '区間５運搬先事業場FAX番号',
      width: 100
    },
    {
      field: 'destination5ChargeName',
      headerName: '区間５運搬先事業場担当者',
      width: 100
    },
    {
      field: 'sect5At',
      headerName: '区間５積込日',
      width: 100
    },
    {
      field: 'destination5At',
      headerName: '区間５荷降日',
      width: 100
    },
    {
      field: 'middleProcessingTraderName0',
      headerName: '処分業者（中間処理）名称',
      width: 300
    },
    {
      field: 'middleProcessingTraderZip0',
      headerName: '処分業者（中間処理）郵便番号',
      width: 300
    },
    {
      field: 'middleProcessingTraderPhone0',
      headerName: '処分業者（中間処理）電話番号',
      width: 300
    },
    {
      field: 'middleProcessingTraderFax0',
      headerName: '処分業者（中間処理）FAX番号',
      width: 300
    },
    {
      field: 'middleProcessingTraderPermit',
      headerName: '処分業者（中間処理）許可番号',
      width: 300
    },
    {
      field: 'middleProcessingDisposalMethod',
      headerName: '処分（中間処理）方法',
      width: 300
    },
    {
      field: 'middleProcessingTraderName',
      headerName: '処分（中間処理）事業場名称',
      width: 300
    },
    {
      field: 'middleProcessingTraderZip',
      headerName: '処分（中間処理）事業場郵便番号',
      width: 300
    },
    {
      field: 'middleProcessingTraderAddress',
      headerName: '処分（中間処理）事業場住所',
      width: 300
    },
    {
      field: 'middleProcessingTraderPhone',
      headerName: '処分（中間処理）事業場電話番号',
      width: 300
    },
    {
      field: 'middleProcessingTraderFax',
      headerName: '処分（中間処理）事業場FAX番号',
      width: 300
    },
    {
      field: 'middleProcessingChargeName',
      headerName: '処分（中間処理）事業場担当者',
      width: 300
    },
    {
      field: 'middleEnd',
      headerName: '処分（中間処理）日',
      type: 'date',
      width: 120
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場名称（予定）',
      width: 300
    },
    {
      field: 'lastProcessingZip',
      headerName: '最終処分場郵便番号（予定）',
      width: 300
    },
    {
      field: 'lastProcessingAddress',
      headerName: '最終処分場住所（予定）',
      width: 300
    },
    {
      field: 'lastProcessingTraderPhone',
      headerName: '最終処分場電話番号（予定）',
      width: 300
    },
    {
      field: 'lastProcessingTraderFax',
      headerName: '最終処分場FAX番号（予定）',
      width: 300
    },
    {
      field: 'lastProcessingChargeName',
      headerName: '最終処分場担当者（予定）',
      width: 300
    },
    {
      field: 'lastProcessingTraderName0',
      headerName: '最終処分場名称（実績）',
      width: 300
    },
    {
      field: 'lastProcessingZip0',
      headerName: '最終処分場郵便番号（実績）',
      width: 300
    },
    {
      field: 'lastProcessingAddress0',
      headerName: '最終処分場住所（実績）',
      width: 300
    },
    {
      field: 'lastProcessingTraderPhone0',
      headerName: '最終処分場電話番号（実績）',
      width: 300
    },
    {
      field: 'lastProcessingTraderFax0',
      headerName: '最終処分場FAX番号（実績）',
      width: 300
    },
    {
      field: 'lastProcessingChargeName0',
      headerName: '最終処分場担当者（実績）',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分日',
      type: 'date',
      width: 120
    },
    {
      field: 'bikou',
      headerName: '備考',
      width: 210
    },
    {
      field: 'bikou1',
      headerName: '備考1',
      width: 210
    },
    {
      field: 'bikou2',
      headerName: '備考2',
      width: 210
    },
    {
      field: 'bikou3',
      headerName: '備考3',
      width: 210
    },
    {
      field: 'bikou4',
      headerName: '備考4',
      width: 210
    },
//    {
//      field: 'bikou5',
//      headerName: '備考5',
//      width: 210
//    },
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }

  ]

  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
  return (
    <>
      <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField id="sect1TransportName" value={filter.sect1TransportName} onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}            
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}          
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}
          density="compact"
          // checkboxSelection
          // disableSelectionOnClick
          // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            ColumnMenu: CustomColumnMenu,　// カスタムカラムメニューを指定する
          }}
//          setDetailOpen2
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageCsvListTableForm
            dataList2={dataList2}
          />
          </Box>
        
      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>
      <SpaceRow height={20} />
      <Box component='div' sx={{ textAlign: 'left' }}>
        <Button
            type='button' variant='contained' color='secondary' onClick={(e) => { e.preventDefault(); handleExportCancelCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（取消分・UTF-8形式）
        </Button>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else if ((currentUser.traderKbn == '0001001000') ||
          (currentUser.traderKbn == '0001000000')){   //事業場区分は中間処理・排出事業者？
  return (
    <>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
//            setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageCsvListTableForm
            dataList2={dataList2}
          />
          </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      {/* <Box component='div' sx={{ textAlign: 'left' }}>
        <Button
            type='button' variant='contained' color='secondary' onClick={(e) => { e.preventDefault(); handleExportCancelCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（取消分・UTF-8形式）
        </Button>
      </Box> */}
      </div>
    </BrowserView>
    </>
  );

}else if (currentUser.traderKbn == '0100000000'){   //事業場区分は収集運搬業者？

  return (
    <>
      <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
//              setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
      </MobileView>

      <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
//            setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageCsvListTableForm
            dataList2={dataList2}
          />
          </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>
      <SpaceRow height={20} />
      <Box component='div' sx={{ textAlign: 'left' }}>
        <Button
            type='button' variant='contained' color='secondary' onClick={(e) => { e.preventDefault(); handleExportCancelCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（取消分・UTF-8形式）
        </Button>
      </Box>
      </div>
      </BrowserView>
    </>
  );
}else if (currentUser.traderKbn == '0000000001'){   //事業場区分はグループ管理者？
  return (
    <>
    <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
//              setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
    </MobileView>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField id="sect1TransportName" value={filter.sect1TransportName} onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
//            setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageCsvListTableForm
            dataList2={dataList2}
          />
          </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      {/* <Box component='div' sx={{ textAlign: 'left' }}>
        <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleExportCancelCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（取消分・UTF-8形式）
        </Button>
      </Box> */}
      </div>
    </BrowserView>
    </>
  );

}else{                                       //事業場区分は管理者以外？
  return (
    <>
    <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
//              setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageCsvListTableForm
            dataList2={dataList2}
          />
          </Box>
        </div>
      </Grid>
    </MobileView>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}            
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
//            setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
        <GarbageCsvListTableForm
          dataList2={dataList2}
        />
        </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <Button
            type='button' variant='contained' color='secondary' onClick={(e) => { e.preventDefault(); handleExportCancelCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（取消分・UTF-8形式）
        </Button>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default GarbageCsvListTable;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
