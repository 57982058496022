// src/components/users/SignIn.jsx
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// style
import { Backdrop,CircularProgress,makeStyles,Grid,Typography,Link,Button,Card, CardHeader, CardContent, } from '@material-ui/core';
import Cookies from 'js-cookie';
// context
import { AuthContext } from '../../App';
// api
import { signIn } from '../../lib/api/auth';
import { getChargeLoginUser } from '../../lib/api/charge';
// component
import SignForm from './SignForm';
import SpaceRow from '../commons/SpaceRow';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  newscard: {
    padding: theme.spacing(2),
    maxWidth: 1000,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.ecotechl.com/">
        ecotechl.com
      </Link>{' '}
      {'2023-'+ new Date().getFullYear()}
      {'.'}
      {'　　'}
      <Link target="_blank" color="inherit" href="https://s3-sdgs12com-manual.s3.ap-northeast-1.amazonaws.com/SDGs12TermsofUse_20230201.pdf">
        利用規約
      </Link>{' '}
    </Typography>
  );
}
function GardSystemLink() {
  const handleClick = () => {
    // 新規タブを開いて遷移
    window.open('https://ezeco.jp/');
  };
  return (
    <Typography variant="h6"  align="center">
      {'旧システム（環境ガードシステム）は　'}
      <Button variant="outlined" onClick={handleClick}>こちら</Button>
    </Typography>
  );
}
function SystemNews() {
  const classes = useStyles();
    return (
          <Card className={classes.newscard}>
          <CardHeader
              title="【お知らせ】"
          />
          <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
              ●2024年10月8日　JWNET定期メンテナンスに伴うシステム停止のお知らせ<br />
               【JWNETシステム停止期間】　2024年10月13日（日）午前0:00 ～ 10月14日（月）午前4:00<br />
               　SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br /><br />
              ●2024年8月5日　JWNETシステム停止のお知らせ<br />
               【JWNETシステム停止期間】　2024年8月10日（土）午前0:00 ～ 8月12日（月）午前4:00<br />
               　SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br />
              ●2024年7月7日　次の機能追加を行いましたのでお知らせ致します。<br />
               ・廃棄物一覧画面に「PDF一括(MAX300件)ダウンロード」ボタンを追加し、検索バー（「データ表示」ボタン）で絞り込んだ300件以内の廃棄物実績報告を１PDFファイルにまとめてダウンロードできるようにしました。<br />
              ●2024年6月23日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者および処理業者の廃棄物一覧画面に「マニフェスト情報修正・取消依頼」ボタンを追加し、選択した廃棄物の修正・取消依頼を行えるようにしました。<br />
                ・また、廃棄物一覧タイトル右に「修正・取消依頼」スイッチを追加し、上記ボタンより依頼した廃棄物の修正・取消の処理状況を確認できるようにしました。<br />
              ●2024年5月12日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者のスマートフォン機能に「担当者作業報告」を追加し、廃棄物一覧の担当名を押下すると担当者作業報告画面が表示され、作業開始/作業登録/作業終了の登録と編集・削除を行えるようにしました。
                また、排出事業場での本登録時および区間１荷卸時、区間２積込時、区間２荷卸時を作業報告として自動で作業報告に記録するようにしました。<br />
                ・収集運搬業者のパソコンでのメニューに「担当者作業報告」を追加し、作業期間の設定と担当者選択で作業報告を表示するようにし、PDF出力できるようにしました。<br />
              ●2024年4月22日　JWNET定期メンテナンスに伴うシステム停止のお知らせ【5月】<br />
                【JWNETシステム停止期間】　2024年5月5日（日）午前0:00 ～ 5月6日（月）午前4:00<br />
               　SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br />
              ●2024年3月31日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者のパソコンでのメニューに「仮登録状況・予約一覧」を追加し、排出仮登録用CSVファイルより排出仮登録予約へ登録できるようにしました。
                また、排出仮登録予約へ登録されたデータは、排出予定日当日AM0:01に排出仮登録へ自動移行するようにしました。<br />
                ・処理フロー一覧で排出仮登録用CSVファイル（ひな型）を作成できるようにしました。<br />
              ●2024年3月24日　次の機能改善を行いましたのでお知らせ致します。<br />
                ・スマートフォンでの排出仮登録画面への遷移を廃棄物種類コードに'9999'でも行えるようにしました。<br />
              ●2024年3月10日　次の機能改善を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧、処理フロー一覧の検索バーでエンターキーでもデータ表示するように改善しました。<br />
              ●2024年3月3日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・スマートフォンの排出登録（マニフェスト情報作成）画面に「入力支援」ボタンを追加しました。<br />
                　容器容量と荷姿数量より廃棄物の数量を自動算出し、排出登録画面へ転記するようにしました。<br />
              ●2024年2月25日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・スマートフォンの（廃棄物QRコード読込）ボタンで排出担当者QRコードを読み込むと排出事業者の仮登録一覧（排出仮登録一覧）へ遷移するようにしました。<br />
                ・廃棄物一覧詳細画面にIDを表示するように変更致しました。<br />
              ●2024年2月18日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者 スマートフォンでのログインを「スマホ担当者登録（初回のみ）」を不要とする担当者CD指定で行えるようにしました。<br />
              ●2024年2月11日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者 連携・担当者マスタ画面にスマートフォンでの排出本登録用に「排出担当者ＱＲコード作成」ボタンを追加しました。<br />
              ●2024年2月4日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・収集運搬業者 処理フロー画面に「現場設置用QRコード」作成機能を追加しました。<br />
                ・排出事業者 担当者マスタ画面に排出本登録用「承認コード入りQRコード」印刷機能を追加しました。<br />
                ・スマートフォンの（廃棄物QRコード読込）ボタンで「現場設置用QRコード」を読み込むと、数量入力画面へ遷移するようにしました。処理フロー番号入力が不要になります。<br />
                ・スマートフォンの排出仮登録一覧画面に排出本登録（排出担当者QRコード読込）ボタンを追加し、「承認コード入りQRコード」で排出事業者担当者設定と本登録が行えるようにしました。<br />
          </Typography>
          <Typography variant="h6" color="textSecondary" component="p">
            <Link href="https://www.sdgs12.com/oldnews">＜＜＜過去のお知らせ＞＞＞</Link>
          </Typography>
          </CardContent>
      </Card>
  );
}

const SignIn = () => {
  const history = useHistory();

  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  // ログイン時にGPSデータを取得する関数
  const getGPSLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        // 取得した緯度と経度をサーバーに送信するか、処理に組み込む
        console.log("Latitude: " + latitude + " Longitude: " + longitude);
      }, (error) => {
        console.error("Error getting GPS location:", error);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const signInHandleSubmit = async (e) => {
    e.preventDefault();
    console.log("email", email);
    // GPSデータを取得
    getGPSLocation();

    try {
      setOpen(true); // ローディング状態を開始
      const res = await getChargeLoginUser(email);
      console.log("res", res); 
      if (res.status === 200) {
        const mbluser = res.data[0].id + ":" + res.data[0].chargeCd + ":" + res.data[0].chargeName;
        console.log("mbluser", mbluser);
        Cookies.set('mbl_user', mbluser);
      }
    } catch (e) {
      console.log(e);
      console.error("エラー：", e.message);
    } finally {
      setOpen(false); // 成功時もエラー時もローディング状態を終了
    }
  
    // emailを"/"で分割し、分割された値をそれぞれ変数に格納
    const [emailPart, mblUserPart] = email.split('-');
  
    // 分割したemailの[0]側を使用してparamsを生成
    const params = generateParams(emailPart, password);
  
    try {
      setOpen(!open);
      const res = await signIn(params);
  
      if (res.status === 200) {
        Cookies.set('_access_token', res.headers['access-token']);
        Cookies.set('_client', res.headers['client']);
        Cookies.set('_uid', res.headers['uid']);
  
        setIsSignedIn(true);
        setCurrentUser(res.data.data);
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
      console.error("エラー：", e.message);
      if (e.name != "TypeError") {
        alert('事業者IDまたはパスワードに誤りがあります');
      }
    }
    history.push('/');
    window.location.reload();
  };
  
  // generateParams関数を修正して、emailを引数として受け取るようにします
  const generateParams = (email, password) => {
    const signInParams = {
      email: email + "@sdgs12.com", // ログイン時、@sdgs12.com 入力を不要とする処理
      password: password,
    };
    return signInParams;
  };
  
  return (
    <div>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <h1>産業廃棄物SDGs推進システム</h1>

        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SignForm
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleSubmit={signInHandleSubmit}
          signType='signIn'
        />
        {/* <SpaceRow height={50} />
        <GardSystemLink /> */}
        <SpaceRow height={75} />
        <SystemNews />
      </Grid>
      <SpaceRow height={50} />
      <Copyright />
    </div>
  );
};
export default SignIn;
