import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeUser, getChargeEffective } from '../../lib/api/charge';
import { getChargeSelList } from '../../lib/api/charge_sel';
import { getEgsMTraderList, getEgsMTraderUser } from '../../lib/api/egs_m_trader';
//import { getEgsMLinkageUser } from '../../lib/api/egs_m_linkage';
import { getLinkageUser } from '../../lib/api/linkage';
import { getLinkageList } from '../../lib/api/linkage';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { toxicSubstanceName, transportMethodName } from '../../constants'

import './app.css';
import { AuthContext } from '../../App';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '150ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "70vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));





const EgsMProcessFlowForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, dataList2, buttonType } = props;

  // console.log('value',value);

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);


  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   handleGetList();
  // }, []);

  //const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}
  const [dataListL01, setDataListL01] = useState([]); {/* 選択事業場 */ }
  const [dataListC01, setDataListC01] = useState([]); {/* 最終処分場担当者 */ }

  const [dataListCxx, setDataListCxx] = useState([]); {/* 排出担当者 */ }
  const [dataListC00, setDataListC00] = useState([]); {/* 排出担当者 */ }
  const [dataListC10, setDataListC10] = useState([]); {/* 区間1運搬業者担当者 */ }
  const [dataListC11, setDataListC11] = useState([]); {/* 区間1運搬先業者担当者 */ }
  const [dataListC20, setDataListC20] = useState([]); {/* 区間2運搬業者担当者 */ }
  const [dataListC21, setDataListC21] = useState([]); {/* 区間2運搬先業者担当者 */ }
  const [dataListC30, setDataListC30] = useState([]); {/* 区間3運搬業者担当者 */ }
  const [dataListC31, setDataListC31] = useState([]); {/* 区間3運搬先業者担当者 */ }
  const [dataListC40, setDataListC40] = useState([]); {/* 区間4運搬業者担当者 */ }
  const [dataListC41, setDataListC41] = useState([]); {/* 区間4運搬先業者担当者 */ }
  const [dataListC50, setDataListC50] = useState([]); {/* 区間5運搬業者担当者 */ }
  const [dataListC51, setDataListC51] = useState([]); {/* 区間5運搬先業者担当者 */ }
  const [dataListC70, setDataListC70] = useState([]); {/* 区間5運搬業者担当者 */ }

  const [dataListT01, setDataListT01] = useState([]);

  const [dataListWaste, setDataListWaste] = useState([]); {/* 廃棄物種類 */ }
  const [dataListToxic, setDataListToxic] = useState([]); {/* 有害物質 */ }
  const [dataListDisposal, setDataListDisposal] = useState([]); {/* 処分方法 */ }
  const [dataListTransport, setDataListTransport] = useState([]); {/* 運搬方法 */ }
  const [dataListUnit, setDataListUnit] = useState([]); {/* 単位 */ }
  const [dataListPacking, setDataListPacking] = useState([]); {/* 荷姿 */ }

  const [errorExhaustId, setErrorExhaustId] = useState(false);
  const [errorExhaustChargeId, setExhaustChargeId] = useState(false);
  const [errorWasteCd1, setErrorWasteCd1] = useState(false);
  const [errorWasteCd, setErrorWasteCd] = useState(false);
  const [errorDisposalMethodCd, setErrorDisposalMethodCd] = useState(false);
  const [errorUnitCd, setErrorUnitCd] = useState(false);
  const [errorPackingCd, setErrorPackingCd] = useState(false);
  const [errorLastPlaceFlg, setErrorLastPlaceFlg] = useState(false);
  const [errorLastProcessingTraderId, setErrorLastProcessingTraderId] = useState(false);
  const [errorLastProcessingChargeId, setErrorLastProcessingChargeId] = useState(false);
  const [errorSect1TransportId, setErrorSect1TransportId] = useState(false);
  const [errorApproveflg, setErrorApproveflg] = useState(false);

  const checkValidation = () => {
    let available = true;
    if (!value.exhaustId ||
      !value.exhaustChargeId ||
      !value.wasteCd1 ||
      !value.wasteCd ||
      !value.disposalMethodCd ||
      !value.unitCd ||
      !value.packingCd ||
      !value.lastPlaceFlg ||
      !value.lastProcessingTraderId ||
      !value.lastProcessingChargeId ||
      !value.sect1TransportId ||
      !value.approvalflg
    ) {
      // console.log(
//        !value.exhaustId,
//        !value.exhaustChargeId,
//        !value.wasteCd1,
//        !value.wasteCd,
//        !value.disposalMethodCd,
//        !value.unitCd,
//        !value.packingCd,
//        !value.lastPlaceFlg,
//        !value.lastProcessingTraderId,
//        !value.lastProcessingChargeId,
//        !value.sect1TransportId,
//        !value.approvalflg
//      );
      // console.log(value.approvalflg);
//      available = false;
//      setErrorExhaustId(value.exhaustId === undefined);
//      setExhaustChargeId(!value.exhaustChargeId);
//      setErrorWasteCd1(!value.wasteCd1);
//      setErrorWasteCd(!value.wasteCd);
//      setErrorDisposalMethodCd(!value.disposalMethodCd);
//      setErrorUnitCd(!value.unitCd);
//      setErrorPackingCd(!value.packingCd);
//      setErrorLastPlaceFlg(!value.lastPlaceFlg);
//      setErrorLastProcessingTraderId(!value.lastProcessingTraderId);
//      setErrorLastProcessingChargeId(!value.lastProcessingChargeId);
//      setErrorSect1TransportId(!value.sect1TransportId);
//      setErrorApproveflg(value.approvalflg !== "1");
    }
    return available;
  }

  useEffect(() => {
    handleGetList();
  }, [value.id]);

  const handleGetList = async () => {
    try {
      setOpen(!open);
//      const resT01 = await getEgsMTraderUser(dataList2.exhaustCd);
      // const resT01 = await getEgsMTraderList();
      // setDataListT01(resT01.data);
      //    // console.log('resT01',resT01.data);

      const dataListWaste = await getEgsMWasteclassList();
      setDataListWaste(dataListWaste.data);
      //    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

      const dataListToxic = await getEgsMToxicSubstanceList();
      setDataListToxic(dataListToxic.data);
      //    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

      const dataListDisposal = await getEgsMDisposalMethodList();
      setDataListDisposal(dataListDisposal.data);
      //    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

      const dataListTransport = await getEgsMTransportMethodList();
      setDataListTransport(dataListTransport.data);
      // console.log('sect1TransportMethodCd', value.sect1TraresC20nsportMethodCd, 'dataListTransport', dataListTransport.data);

      const dataListUnit = await getEgsMUnitList();
      setDataListUnit(dataListUnit.data);
      //    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

      const dataListPacking = await getEgsMPackingList();
      setDataListPacking(dataListPacking.data);
      //    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);

//      const resC00 = await getEgsMChargeList();

      const resCxx = await getEgsMChargeList();
      setDataListCxx(resCxx.data);

      const resC00 = await getChargeEffective(value.exhaustCd);
      setDataListC00(resC00.data);
      // console.log("resC00.data",resC00.data,"value.exhaustCd",value.exhaustCd);

      const resC10 = await getChargeEffective(value.sect1TransportCd);
      setDataListC10(resC10.data);
      // console.log("resC10.data",resC10.data,"value.sect1TransportCd",value.sect1TransportCd);
      const resC11 = await getChargeEffective(value.destination1Cd);
      setDataListC11(resC11.data);
      // console.log("resC11.data",resC11.data,"value.destination1Cd",value.destination1Cd);

      const resC20 = await getChargeEffective(value.sect2TransportCd);
      setDataListC20(resC20.data);
      // console.log("resC20.data",resC20.data,"value.sect2TransportCd",value.sect2TransportCd);
      const resC21 = await getChargeEffective(value.destination2Cd);
      setDataListC21(resC21.data);
      // console.log("resC21.data",resC21.data,"value.destination2Cd",value.destination2Cd);

      const resC30 = await getChargeEffective(value.sect3TransportCd);
      setDataListC30(resC30.data);
      // console.log("resC30.data",resC30.data,"value.sect3TransportCd",value.sect3TransportCd);
      const resC31 = await getChargeEffective(value.destination3Cd);
      setDataListC31(resC31.data);
      // console.log("resC31.data",resC31.data,"value.destination3Cd",value.destination3Cd);

      const resC40 = await getChargeEffective(value.sect4TransportCd);
      setDataListC40(resC40.data);
      // console.log("resC40.data",resC40.data,"value.sect4TransportCd",value.sect4TransportCd);
      const resC41 = await getChargeEffective(value.destination4Cd);
      setDataListC41(resC41.data);
      // console.log("resC41.data",resC41.data,"value.destination4Cd",value.destination4Cd);

      const resC50 = await getChargeEffective(value.sect5TransportCd);
      setDataListC50(resC50.data);
      // console.log("resC50.data",resC50.data,"value.sect5TransportCd",value.sect5TransportCd);
      const resC51 = await getChargeEffective(value.destination5Cd);
      setDataListC51(resC51.data);
      // console.log("resC51.data",resC51.data,"value.destination5Cd",value.destination5Cd);

      const resC70 = await getChargeEffective(value.lastProcessingTraderCd);
      setDataListC70(resC70.data);
      // console.log("resC70.data",resC70.data,"value.exhaustCd",value.lastProcessingTraderCd);

      // const resC01 = await getChargeSelList();
      // setDataListC01(resC01.data);
      //    // console.log('resC01',resC01.data);

      //    const resT01 = await getEgsMTraderList();
      //    const resT01 = await getEgsMLinkageUser(currentUser.traderCd);
      //    const resT01 = await getEgsMLinkageUser(value.sect1TransportMethodCd);
      const resL01 = await getLinkageUser(currentUser.traderCd);
//      const resL01 = await getLinkageList();

//0829      // console.log('currentUser.traderCd', currentUser.traderCd, resL01.data);
      setDataListL01(resL01.data);

      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
//  // console.log('dataListT01',dataListT01);

  // const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
  // // console.log('wastedataList',wastedataList);

  // const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
  //// console.log('toxicdataList',toxicdataList);

  // const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
  //// console.log('disposaldataList',disposaldataList);

  //const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
  // const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
  // const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
  // const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
  // const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
  // const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
  // // console.log('transport', value.sect1TransportMethodCd, value.sect2TransportMethodCd, value.sect3TransportMethodCd, value.sect4TransportMethodCd, value.sect5TransportMethodCd,
  //   trans1dataList, trans2dataList);

  // const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitCd != '' });
  //// console.log('unitdataList',unitdataList);

  // const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
  //// console.log('packingdataList',packingdataList);

//0829  const dataListL00 = dataListL01.filter(dataListL01 => { return dataListL01.egsMTraderId1 == value.exhaustId });  // 0617

  // 排出事業場　変更不可
  // // console.log('排出・運搬先・運搬先担当・最終<', value.exhaustId, ':', value.destination1Id, ':', value.destination1ChargeId, ':', value.lastProcessingTraderId, '>')
  //const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.exhaustId });
//  const traderdataList00 = dataListL01.filter(dataListL01 => { return dataListL01.egsMTraderId2 == value.exhaustId });
//0829  const traderdataList00 = dataListL00.filter(dataListL00 => { return dataListL00.egsMTraderId1 == value.exhaustId });
  //// console.log('traderDataList00',traderdataList00);
  // const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.egsMTraderId == value.exhaustId });    //dataListC01不要？
  //const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
  // // console.log('chargedataList00',value.exhaustId,':',value.exhaustChargeId,':',chargedataList00);

  // 最終処分場
const traderdataList07 = dataListL01.filter(dataListL01 => { return dataListL01.traderKbn2 == '0001000000'
                                                                 || dataListL01.traderKbn2 == '0001001000'
                                                                 || dataListL01.traderKbn2 == '0000100000'
                                                                 || dataListL01.traderKbn2 == '1000100000' });
//0829  const traderdataList07 = dataListL00.filter(dataListL00 => {
//0829    return dataListL00.traderKbn2 == '0000100000'
//0829      || dataListL00.traderKbn2 == '1000100000'
//0829      || dataListL00.traderKbn2 == '0001001000'
//0829  });
  //const traderdataList07 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.lastProcessingTraderId });
  //// console.log('traderDataList07',traderdataList07);
//const chargedataList70 = dataListC70.filter(dataListC70 => { return dataListC70.egsMTraderId == value.lastProcessingTraderId });
const chargedataList70 = dataListCxx.filter(dataListCxx =>
  { return value.lastProcessingTraderId != 0 && dataListCxx.egsMTraderId == value.lastProcessingTraderId &&
                                                dataListCxx.effectiveflg != '9'});


  //// console.log('lastProcessingTraderId',value.lastProcessingTraderId,':',value.lastProcessingChargeId,':',chargedataList70)

  // 区間１～５
  //const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect1TransportId });
  //const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
//  const traderdataList01 = dataListL00.filter(dataListL00 => { return dataListL00.egsMTraderId1 == value.sect1TransportId && dataListL00.traderKbn2 == '1000000000' });
//0829  const traderdataList01 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0100000000' });
  // const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });
  //// console.log('sect1TransportId',value.sect1TransportId,':',value.sect1TransportChargeId,':',chargedataList01)

  //const traderdataListD1 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' });
//0829  const traderdataListD1 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' || dataListL00.traderKbn2 == '0001000000' });    // 中間処理も追加
  // const chargedataListD1 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });

//0829  const traderdataList02 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0100000000' });
  // const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });

//0829  const traderdataListD2 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' || dataListL00.traderKbn2 == '0001000000' });
  // const chargedataListD2 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });

//0829  const traderdataList03 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0100000000' });
  // const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });

//0829  const traderdataListD3 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' || dataListL00.traderKbn2 == '0001000000' });
  // const chargedataListD3 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });

//0829  const traderdataList04 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0100000000' });
  // const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });

//0829  const traderdataListD4 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' || dataListL00.traderKbn2 == '0001000000' });
  // const chargedataListD4 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });

//0829  const traderdataList05 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0100000000' });
  // const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

//0829  const traderdataListD5 = dataListL00.filter(dataListL00 => { return dataListL00.traderKbn2 == '0010000000' || dataListL00.traderKbn2 == '0001000000' });
  // const chargedataListD5 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });


  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpaceRow height={20} />

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                フロー名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveName}</Typography>
              <TextField id='reserveName' hiddenLabel variant="outlined" type='text' name='reserveName' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveName} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                契約日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveDate}</Typography>
              <TextField id='reserveDate' label='' hiddenLabel variant="outlined" type='date' name='reserveDate' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveDate} fullWidth />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ID：{value.id}
              </Typography>
            </Box>
      </Grid>
{/*}          <Grid item xs> */}
          {/* <Grid item xs={1}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='id' label='id' hiddenLabel variant="outlined" type='text' name='id' halfWidth margin="dense" value={value.id} fullWidth />
            </Box>
          </Grid> */}
          <Grid item xs />
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                フロー番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={3}>
              <Typography variant="h7" style={{ fontSize: 20, fontWeight: 700 }}>
                {value.reserveNo}
              </Typography>
              {/* <TextField id='reserveNo' label="" hiddenLabel variant="outlined" type='text' name='reserveNo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveNo} fullWidth /> */}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                フローサブ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='reserveSubno' label='' hiddenLabel variant="outlined" type='text' name='reserveSubno' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.reserveSubno} fullWidth />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業場
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={3}>
              <Typography variant="h7" style={{ fontSize: 20, fontWeight: 700 }}>
                {value.exhaustName}
              </Typography>
              {/* <TextField id='exhaustName' label="" hiddenLabel variant="outlined" type='text' name='exhaustName' fullWidth margin="dense" value={value.exhaustName} /> */}
{/*}              <Select
                error={errorExhaustId}
                id='exhaustId'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustId'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                value={value.exhaustId}
                fullWidth
              >
                {traderdataList00.map((traderdataList00) => (
                  <MenuItem
                    key={traderdataList00.egsMTraderId1}    // id
                    value={traderdataList00.egsMTraderId1}  // id
                  >
                    {traderdataList00.traderName1}
                  </MenuItem>
                ))}
                </Select>  */}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              排出担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.exhaustChargeName}</Typography>
              <Select
                id='exhaustChargeId'
                label='排出担当者'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
                defaultValue={value.exhaustChargeId}
                fullWidth
//0829                error={errorExhaustChargeId}
              >
                {dataListC00.map((dataListC00) => (
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物種類
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={3}>
              <Typography variant="h7" style={{ fontSize: 16, fontWeight: 700 }}>
                {value.wasteKindName}
              </Typography>
            {/* <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.wasteKindName}</Typography> */}
            {/* <TextField id='wasteKindName' label="" hiddenLabel variant="outlined" type='text' name='wasteKindName' fullWidth margin="dense" value={value.wasteKindName} /> */}
            {/* <Select
                id='wasteKindCd'
                label='廃棄物種類'
                variant="outlined"
                type='text'
                name='wasteKindCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteKindCd}
                fullWidth
//0829                error={errorWasteCd}
              >
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
                </Select> */}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                単位
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.unitName}</Typography>
            {/* <TextField id='unitName' label="" hiddenLabel variant="outlined" type='text' name='unitName' fullWidth margin="dense" value={value.unitName} /> */}
            <Select
              id='unitCd'
              label='単位'
              variant="outlined"
              type='text'
              name='unitCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.unitCd}
              fullWidth
//0829                error={errorUnitCd}
            >
              {dataListUnit.map((dataListUnit) => (
                <MenuItem
                  key={dataListUnit.unitCd}
                  value={dataListUnit.unitCd}
                >
                  {dataListUnit.unitCd}:{dataListUnit.unitName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={3}>
              <Typography variant="h7" style={{ fontSize: 16, fontWeight: 700 }}>
                {value.wasteName}
              </Typography>
            {/* <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.wasteName}</Typography> */}
            {/* <TextField id='wasteName' label="" hiddenLabel variant="outlined" type='text' name='wasteName' fullWidth margin="dense" value={value.wasteName} /> */}
              {/* <Select
                id='wasteCd'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteCd}
                fullWidth
//0829                error={errorWasteCd}
              >
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
              </Select> */}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                荷姿
              </InputLabel>
            </Box>
          </Grid>
{/*}          <Grid item xs={1}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                荷姿
              </InputLabel>
            </Box>
                </Grid>  */}
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.packingName}</Typography>
            {/* <TextField id='packingName' label="" hiddenLabel variant="outlined" type='text' name='packingName' fullWidth margin="dense" value={value.packingName} /> */}
              <Select
                id='packingCd'
                label='荷姿'
                variant="outlined"
                type='text'
                name='packingCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.packingCd}
                fullWidth
//0829                error={errorPackingCd}
              >
                {dataListPacking.map((dataListPacking) => (
                  <MenuItem
                    key={dataListPacking.packingCd}
                    value={dataListPacking.packingCd}
                  >
                    {dataListPacking.packingCd}:{dataListPacking.packingName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                メモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
{/*}
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' label='' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
*/}
           <Box bgcolor="primary.gray" color="primary.black" p={3}>
{/*}            <Typography variant="h7" style={{ fontWeight: 700 }}>{ dataList2.memo }</Typography>  */}
{/*}            <Typography variant="h7" style={{ fontSize: 16, fontWeight: 700 }}>{value.memo}</Typography>  */}
            <TextField id='memo' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo} fullWidth />
           </Box>
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>
          <Box p={1} flex={1}>
            <Grid
              container
              spacing={1}
            >
              <Grid xs item>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd01)] }</Typography> */}
                <Typography variant="h7" style={{ fontWeight: 700 }}>{ dataList2.toxicName01 }</Typography>
                <Select fullWidth id='toxicCd01' label='有害物質１' variant="outlined" type='text' name='toxicCd01' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd01} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd02)] }</Typography> */}
                <Typography variant="h7" style={{ fontWeight: 700 }}>{ dataList2.toxicName02 }</Typography>
                <Select fullWidth id='toxicCd02' label='有害物質２' variant="outlined" type='text' name='toxicCd02' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd02} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName03}</Typography>
                <Select fullWidth id='toxicCd03' label='有害物質３' variant="outlined" type='text' name='toxicCd03' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd03} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
               </Select>
              </Grid>
              <Grid xs item>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName04}</Typography>
                <Select fullWidth id='toxicCd04' label='有害物質４' variant="outlined" type='text' name='toxicCd04' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd04} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName05}</Typography>
                <Select fullWidth id='toxicCd05' label='有害物質５' variant="outlined" type='text' name='toxicCd05' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd05} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName06}</Typography>
                <Select fullWidth id='toxicCd06' label='有害物質６' variant="outlined" type='text' name='toxicCd06' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd06} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                最終処分の場所
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={3}>
              <RadioGroup
                aria-labelledby=""
                defaultValue={"1"}
                id="lastPlaceFlg"
                name="lastPlaceFlg"
                aria-orientation='horizontal'
                row
                onChange={e => handleChange(e)}
                value={value.lastPlaceFlg}
//0829                error={errorLastPlaceFlg}
              >
                <FormControlLabel value={"1"} control={<Radio />} label="委託契約書記載のとおり" />
                <FormControlLabel value={"2"} control={<Radio />} label="当欄記載のとおり" />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分方法
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.disposalMethodName}</Typography>
              <Select
                id='disposalMethodCd'
                label='処分方法'
                variant="outlined"
                type='text'
                name='disposalMethodCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.disposalMethodCd}
                fullWidth
//0829                error={errorDisposalMethodCd}
              >
                {dataListDisposal.map((dataListDisposal) => (
                  <MenuItem
                    key={dataListDisposal.disposalMethodCd}
                    value={dataListDisposal.disposalMethodCd}
                  >
                    {dataListDisposal.disposalMethodCd}:{dataListDisposal.disposalMethodName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{ borderBottomWidth: 2, borderColor: 'black' }}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                最終処分場
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              
              <Typography variant="h7" style={{ fontWeight: 700 }}>  {/* fontSize: 16,  */}
                変更前：{value.lastProcessingTraderName}
              </Typography>
{/*}              
             <TextField id='lastProcessingTraderName' label="" hiddenLabel variant="outlined" type='text' name='lastProcessingTraderName' fullWidth margin="dense" value={value.lastProcessingTraderName} />
*/}            
              <Select
                id='lastProcessingTraderId'
                label='最終処分場'
                variant="outlined"
                type='text'
                name='lastProcessingTraderId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastProcessingTraderId}
                fullWidth
                error={errorLastProcessingTraderId}
              >
                {traderdataList07.map((traderdataList07) => (
                  <MenuItem
                    key={traderdataList07.egsMTraderId2}
                    value={traderdataList07.egsMTraderId2}
                  >
                    {traderdataList07.traderName2}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分場担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.lastProcessingChargeName}</Typography>
              <Select
                id='lastProcessingChargeId'
                label='最終処分事業場担当者'
                variant="outlined"
                type='text'
                name='lastProcessingChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastProcessingChargeId}
                fullWidth
//0829                error={errorLastProcessingChargeId}
              >
                {chargedataList70.map((chargedataList70) => (
                  <MenuItem
                    key={chargedataList70.id}
                    value={chargedataList70.id}
                  >
                    {chargedataList70.chargeCd}:{chargedataList70.chargeName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬情報
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬方法
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  車両番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬先事業場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderRightWidth: 0 }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  備考
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <KukanData1
          handleChange={handleChange}
          value={value}
          dataList2={dataList2}
          dataListC10={dataListC10}
          dataListC11={dataListC11}
          dataListTransport={dataListTransport}
        />
        <KukanData2
          handleChange={handleChange}
          value={value}
          dataList2={dataList2}
          dataListC20={dataListC20}
          dataListC21={dataListC21}
          dataListTransport={dataListTransport}
        />
        <KukanData3
          handleChange={handleChange}
          value={value}
          dataList2={dataList2}
          dataListC30={dataListC30}
          dataListC31={dataListC31}
          dataListTransport={dataListTransport}
        />
        <KukanData4
          handleChange={handleChange}
          value={value}
          dataList2={dataList2}
          dataListC40={dataListC40}
          dataListC41={dataListC41}
          dataListTransport={dataListTransport}
        />
        <KukanData5
          handleChange={handleChange}
          value={value}
          dataList2={dataList2}
          dataListC50={dataListC50}
          dataListC51={dataListC51}
          dataListTransport={dataListTransport}
        />

        <Grid
          container
          spacing={0}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分受託者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box bgcolor="primary.gray" color="primary.black" p={1} flex alignItems={"center"}>
              <Typography variant='h7'style={{ fontSize: 16, fontWeight: 700 }}>
              {value.processTrustTraderName} {/* 特定非営利活動法人エコ・テクル [中間最終用]  */}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}
              style={{ borderLeft: "1px solid #CCC" }}
            >
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                承認済み
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Checkbox
                sx={{
                  color: errorApproveflg ? "red" : "gray",
                }}
                name='approvalflg'
                value={value.approvalflg === "1"}  onChange={e => {
                  handleChange({
                    target: {
                      name: 'approvalflg',
                      value: e.target.checked ? "1" : "0"
                    }
                  });
                }} />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
            handleSubmit(e);
            // if (checkValidation()) {
            //   handleSubmit(e);
            // }
          }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>
    </>
  );
};
export default EgsMProcessFlowForm;

function KukanData1(props) {
  const { handleChange, value, dataList2, dataListC10, dataListC11, dataListTransport } = props;
  const classes = useStyles();
  if (value.sect1TransportCd != "0"){
    return (
      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              区間１
            </InputLabel>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.sect1TransportName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect1TransportChargeName}</Typography>
              <Select fullWidth id='sect1TransportChargeId' label='区間１運搬担当者' variant="outlined" type='text' name='sect1TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeId} >
                {dataListC10.map((dataListC10) => (<MenuItem key={dataListC10.id} value={dataListC10.id} > {dataListC10.chargeCd}:{dataListC10.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
                              {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd01)] }</Typography> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect1TransportMethodCd)]}</Typography>
              <Select fullWidth id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect1TransportMethodCd} >
                {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect1CarNo}</Typography>
              <TextField id='sect1CarNo' hiddenLabel variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.destination1Name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination1ChargeName}</Typography>
              <Select fullWidth id='destination1ChargeId' label='区間１運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                {dataListC11.map((dataListC11) => (<MenuItem key={dataListC11.id} value={dataListC11.id} > {dataListC11.chargeCd}:{dataListC11.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo1}</Typography>
              <TextField id='memo1' hiddenLabel variant="outlined" type='text' name='memo1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    )
  }else{
    return (
      <Grid item xs={2}>
      <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
          区間１
        </Typography>
      </Box>
    </Grid>
    )
  }
}
function KukanData2(props) {
  const { handleChange, value, dataList2, dataListC20, dataListC21, dataListTransport } = props;
  const classes = useStyles();
  if (value.sect2TransportCd != "0"){
    return (
        <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間２
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect2TransportName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect2TransportChargeName}</Typography>
            <Select fullWidth id='sect2TransportChargeId' label='区間２運搬担当者' variant="outlined" type='text' name='sect2TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportChargeId} >
              {dataListC20.map((dataListC20) => (<MenuItem key={dataListC20.id} value={dataListC20.id} > {dataListC20.chargeCd}:{dataListC20.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect2TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect2TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect2CarNo}</Typography>
            <TextField id='sect2CarNo' hiddenLabel variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} fullWidth />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination2Name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination2ChargeName}</Typography>
            <Select fullWidth id='destination2ChargeId' label='区間２運搬先担当者' variant="outlined" type='text' name='destination2ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeId} >
              {dataListC21.map((dataListC21) => (<MenuItem key={dataListC21.id} value={dataListC21.id} > {dataListC21.chargeCd}:{dataListC21.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo2}</Typography>
            <TextField fullWidth id='memo2' variant="outlined" type='text' name='memo2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} />
          </Box>
        </Grid>
      </Grid>
      </Grid>
    )
  }else{
    return (
      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間２
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }
}
function KukanData3(props) {
  const { handleChange, value, dataList2, dataListC30, dataListC31, dataListTransport } = props;
  const classes = useStyles();
  if (value.sect3TransportCd != "0"){
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間３
          </Typography>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect3TransportName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect3TransportChargeName}</Typography>
            <Select fullWidth id='sect3TransportChargeId' label='区間３運搬担当者' variant="outlined" type='text' name='sect3TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportChargeId} >
              {dataListC30.map((dataListC30) => (<MenuItem key={dataListC30.id} value={dataListC30.id} > {dataListC30.chargeCd}:{dataListC30.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect3TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect3TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect3CarNo}</Typography>
            <TextField fullWidth id='sect3CarNo' hiddenLabel variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination3Name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination3ChargeName}</Typography>
            <Select fullWidth id='destination3ChargeId' label='区間３運搬先担当者' variant="outlined" type='text' name='destination3ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeId} >
              {dataListC31.map((dataListC31) => (<MenuItem key={dataListC31.id} value={dataListC31.id} > {dataListC31.chargeCd}:{dataListC31.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo3}</Typography>
            <TextField fullWidth id='memo3' variant="outlined" type='text' name='memo3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
    )
  }else{
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間３
          </Typography>
        </Box>
      </Grid>
    </Grid>
    )
  }
}
function KukanData4(props) {
  const { handleChange, value, dataList2, dataListC40, dataListC41, dataListTransport } = props;
  const classes = useStyles();
  if (value.sect4TransportCd != "0"){
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間４
          </Typography>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect4TransportName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect4TransportChargeName}</Typography>
            <Select fullWidth id='sect4TransportChargeId' label='区間４運搬担当者' variant="outlined" type='text' name='sect4TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportChargeId} >
              {dataListC40.map((dataListC40) => (<MenuItem key={dataListC40.id} value={dataListC40.id} > {dataListC40.chargeCd}:{dataListC40.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect4TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect4TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect4CarNo}</Typography>
            <TextField fullWidth id='sect4CarNo' hiddenLabel variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination4Name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination4ChargeName}</Typography>
            <Select fullWidth id='destination1ChargeId' label='区間４運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
              {dataListC41.map((dataListC41) => (<MenuItem key={dataListC41.id} value={dataListC41.id} > {dataListC41.chargeCd}:{dataListC41.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo4}</Typography>
            <TextField fullWidth id='memo4' hiddenLabel variant="outlined" type='text' name='memo4' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
    )
  }else{
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間４
          </Typography>
        </Box>
      </Grid>
    </Grid>
    )
  }
}
function KukanData5(props) {
  const { handleChange, value, dataList2, dataListC50, dataListC51, dataListTransport } = props;
  const classes = useStyles();
  if (value.sect3TransportCd != "0"){
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間５
          </Typography>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect5TransportName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect5TransportChargeName}</Typography>
            <Select fullWidth id='sect5TransportChargeId' label='区間５運搬担当者' variant="outlined" type='text' name='sect5TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportChargeId} >
              {dataListC50.map((dataListC50) => (<MenuItem key={dataListC50.id} value={dataListC50.id} > {dataListC50.chargeCd}:{dataListC50.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect5TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect5TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect5CarNo}</Typography>
            <TextField fullWidth id='sect5CarNo' hiddenLabel variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination5Name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination5ChargeName}</Typography>
            <Select fullWidth id='destination1ChargeId' label='区間５運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
              {dataListC51.map((dataListC51) => (<MenuItem key={dataListC51.id} value={dataListC51.id} > {dataListC51.chargeCd}:{dataListC51.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo5}</Typography>
            <TextField fullWidth id='memo5' variant="outlined" type='text' name='memo5' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
    )
  }else{
    return (
      <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間５
          </Typography>
        </Box>
      </Grid>
    </Grid>
    )
  }
}
