// /src/lib/api/linkage.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getLinkageList = () => {
  return client.get('/linkages');
};

// 事業所指定一覧 idは事業所CD
export const getLinkageUser = (id) => {
  return client.get(`/linkages/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD
export const getLinkageUserExhaust = (id) => {
  return client.get(`/linkages/${id}/showuserExhaust`);
};

// 事業所指定一覧 idは事業所CD
export const getLinkageUserId = (id) => {
  return client.get(`/linkages/${id}/showuserId`);
};

// 担当者指定一覧 idはグループ
export const getLinkageGroup = (id) => {
  return client.get(`/linkages/${id}/showgroup`);
};

// 担当者指定一覧 idはグループ
export const getLinkageGroupExhaust = (id) => {
  return client.get(`/linkages/${id}/showgroupExhaust`);
};

// 担当者指定一覧 idはグループ
export const getLinkageExhaust = (id) => {      // idの値は不問
  return client.get(`/linkages/${id}/showExhaust`);
};

// 詳細（追加・更新用）
export const getLinkageDetail = (id) => {
  return client.get(`/linkages/${id}`);
};

// 新規作成
// header情報を追加
export const createLinkage = (params) => {
  return client.post('/linkages', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateLinkage = (id, params) => {
  return client.patch(`/linkages/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteLinkage = (id) => {
  return client.delete(`/linkages/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
